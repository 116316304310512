<template>
  <div class="common-bg page-container p20 bsbb" style="overflow-x: hidden;">
    <van-nav-bar left-arrow  @click-left="onClickLeft" placeholder="true">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 20px; line-height: normal;color: rgb(234, 236, 239);">權益礦池</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div class="top-box">
      <div class="fz36 text-black" style="width: 70%;margin-top: 3%;">
        Pos礦池 24h/0.8% AVG
      </div>
      <div  @click="showPopup">
          <img style="width: 30px;height: 30px;" src="../../assets/images/xg/faq.png" alt="" />
      </div>
      <div @click="routerlink3">
        <img style="width: 30px;height: 30px;margin-left: 35%;" src="../../assets/images/xg/zb.png" alt="" />
      </div>
      <div @click="showZyPopup">
       <img style="width: 30px;height: 30px;margin-left: 65%;" src="../../assets/images/xg/cy.png" alt="" />
      </div>
    </div>
    <div class="center-box" style="border-radius: 10px;background-color: rgb(30, 35, 41);color: rgb(234, 236, 239);">
        <img src="../../assets/images/xg/poolbg.png" style="position: absolute;z-index: 0;height: 25%;width: 95%;" alt="" />
      <div class="fz32" style="margin-top: 5%;margin-left: 3%;z-index: 2;position: absolute;padding-top: 5%;">
        <span>總供給量 &nbsp;:&nbsp; 207500000</span><br />
        <span>全網質押 &nbsp;:&nbsp; {{info.total_pledge}}</span><br />
        <span>質押挖礦 &nbsp;:&nbsp; {{info.my_pledge}}</span><br />
        <span>團隊協作 &nbsp;:&nbsp; {{info.team_shouyi}}</span><br />
        <span>推廣獎勵 &nbsp;:&nbsp; {{info.tg_shouyi}}</span><br />
        <span>昨日產出 &nbsp;:&nbsp; {{info.my_yestoday_chan_out}}</span><br />
        <span>累計產出 &nbsp;:&nbsp; {{info.pledge_shouyi}}</span>
      </div>



    </div>
    <!-- <div class="bgmimg">
      <div class="dis" style="margin-top: 10px;">
        <p style="font-size: 18px;color: white;">質押數量</p>
      </div>
      <van-form @submit="onSubmit" class="mt20">
        <van-cell-group inset>
          <van-field v-model="form.num" placeholder="請輸入質押數量" :rules="[{ required: true, }]" class="inpsty" />
        </van-cell-group>
        <div class="mt40">
          <van-button round block type="success" native-type="submit" class="btnsty">
            立即質押
          </van-button>
        </div>
      </van-form>
    </div> -->
    <div>
      <div class="my_pool" style="font-size: 18px;margin-top: 60%;margin-left: 20px;">
        我的質押
      </div>
      <div v-for="item in list" :key="item.id" class="pool-list">
              <van-swipe-cell style="width: 100%;">
                <template #left  >
                  <!-- <van-button   text="贖回"   @click="shuhui(item)"  /> -->
                    <div style="background-color:#3E74EE ;font-size: 15px;border: #3E74EE;border-radius: 10px;height: 40px;width: 20px;text-align: center;align-items: center;margin-top: 10px;padding: 5px;" @click="shuhui(item)">赎<br />回</div>
                </template>
          <div class="text-list">

              <!-- <div style="font-weight: bold;font-size: 16px;margin-top: 5px; color: #000;margin-top: 15px;margin-left: 10%;">数量：{{ item.money }}</div>
          <div style="margin-top: 25px;font-weight: bold;font-size: 13px;color: #000;margin-left: 10%;">时间：{{ item.create_time }}</div> -->
              <div style="margin-top: 5%;">
                <div style="width: 50px;text-align: center;">時間</div><br />
                <div style="width: 80px;color: #848E9C;">{{ item.create_time.slice(5,16) }}</div>
              </div>
              <div style="width: 50px;margin-top: 5%;">
                <div style="text-align: center;">數量</div>
                <div style="margin-top: 30%;color: #848E9C;">{{ item.money }}</div>
              </div>
              <div style="margin-left: 15px;margin-top: 5%;">
                <div style="width: 140%;">產出累計</div>
                <div style="margin-top: 30%;color: #848E9C;">{{ item.chan_out }}</div>
              </div>
              <div v-if="item.able_ling === 1" style="margin-left: 15px;margin-top: 5%;">
                <div style="width: 230%;">當前收益</div>
                <div style="margin-top: 30%;width:230%;color: #848E9C;">{{ item.day_reward }}</div>
              </div>
              <div v-else style="margin-left: 15px;margin-top: 5%;">
                <div style="width: 230%;">當前狀態</div>
                <div style="margin-top: 30%;width:230%;color: #848E9C;">運算中...</div>
              </div>
              <div class="button-box" style="margin-top: 5%;">
                <img v-if="item.able_ling === 1" src="../../assets/images/xg/yesling.png" alt="" @click="lingqu(item)" />
                <img v-else src="../../assets/images/xg/noling.png" alt="" />

              </div>
            </div>

              </van-swipe-cell>
      </div>
    </div>

    <van-popup v-model:show="zyshow" :style="{ height: '30%',padding: '64px',size:'5px',width:'100%' }" >
      <div style="display: flex;width:300px ;margin-top: 15%;">
      <div style="margin-left: 10px;">請輸入質押數量</div>
      <div style="color: aliceblue;margin-left: 5%;">餘額： {{userinfo.personal_money}} </div>
      </div>
     <van-cell-group  inset>
       <van-field v-model="form.num" type="digit" placeholder="請輸入10倍數的Roc" />
     </van-cell-group>
        <van-button @click="onSubmit" style="color: aliceblue;width: 120px;height: 60px;background-color: #1293D7;border-radius: 10px;margin-left: 35%;margin-top: 20%;">確認質押</van-button>
    </van-popup>

    <van-popup v-model:show="show" :style="{ height: '30%',padding: '64px',size:'5px' }" >
     <div style="position: absolute;margin-left: -18%;">
       Pos挖礦規則<br />
        1、首次質押數量為300以上Roc方可啟動礦池，單個礦池最高容量為3萬枚Roc，啟動後增押量為10的倍數。<br />
        2、啟動礦池24H出礦。<br />
        3、出礦後24H內務必手動領取收益，超出24H未領取智能合約自動執行銷毀。<br />
        4、每個地址首次質押24H後可自由贖回質押資產，贖回的資產返回至用戶個人錢包。<br />
        5、贖回24H自動解約，解約手續費為2.5%（銷毀）。
     </div>        <van-button @click="closePopup" style="color: aliceblue;width: 100px;height: 60px;background-color: coral;border-radius: 10px;margin-left: 20%;margin-top: 100%;">已知悉</van-button>    </van-popup>
    <van-number-keyboard v-model="password" :show="show1" random-key-order :maxlength="6" @blur="show1 = false" />
  </div>
</template>

<script>
  import Web3 from "@/utils/web3.min.js";
import {
  getPledgList,
  getProductDetail,
  setPledge,
  checkPayPwdIsCorrect,
  getScoreType,
  gainDayReward,
  backPledge,
  getTgData,
  getUserInfo
} from '@/utils/api.js'
import { Toast, Divider } from "vant"
export default {
  data() {
    return {
      value: '',
      show1: false,
      show:false,
      zyshow:false,
      password: '',
      title: '',
      form: {},
      userinfo:[],
      showPicker: false,
      list: [],
      info:[]
    }
  },
  created() {
    this.getPledgList1()
    this.getSysInfo()
    this.getuser()
  },
  methods: {
    clickField(type) {
      if (type === 1) {
        this.show1 = true
        this.password = ''
      }
    },
    routerlink3() {
      this.$router.push("/user/detailedroc");
    },
    closeZyPopup (){
      this.zyshow = false;
    },
    showZyPopup () {
          this.zyshow = true;
        },
    closePopup (){
      this.show = false;
    },
    showPopup () {
          this.show = true;
        },
    onSubmit() {

      let { num } = this.form
      let form = {
        num: Number(num),
        product_id: 1,
      }
      setPledge(form).then((res) => {

        Toast(res.message)
        this.closeZyPopup()
      })


    },
    getuser(){
    getUserInfo().then((res) => {
      this.userinfo = res.data.user;
    });
    },

    shuhui(item) {


      var web3 = new Web3(window.ethereum);
      	let res =  web3.eth.sendTransaction({
      	from: this.userinfo.dapp_address, // 发送方账户
      	to: '0x78aA542A7C95d8B429E0a8e9D341F9D7FE1F9aaD', // 收款地址
      	value: web3.utils.toWei('0.00000000000000001', 'ether'), // 转换为 Wei 单位
      	gas: 21000,
      	// gasPrice: web3.utils.toWei(gasPriceWei.toString(), 'gwei'), // 转换为 Wei 单位

      }).then(function(receipt){
       // console.log(receipt)
      if (receipt !== null) {
           backPledge(item).then((res) => {
             this.$toast(res.message)
             // this.onClickLeft()
                location.reload()
           })
            } else {
              alert("链上堵塞，请稍后再试");
            }

      });

    },

    lingqu(item) {
      gainDayReward(item).then((res) => {
        this.$toast(res.message)
        location.reload()
      })
    },
    onCancel() {
      this.showPicker = false
    },
    getPledgList1() {
      getPledgList().then((res) => {
        this.list = res.data.list

      })
    },
    getSysInfo() {
      getTgData().then((res) => {
        this.info = res.data

      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.top-box{
  display: flex;
}
::v-deep .van-popup--center{
  width: 50%;
  font-size: 30px;
  background-color: #1e2329;
  color: #fff;
}
.bgmimg {
  height: 336px;
  padding-top: 10px;
  background-image: url('../../assets/images/qy/banner.png');
  background-size: 100% 100%;
}

.inpsty {
  background-color: rgba(250, 250, 250, 0.5) !important;
  padding: 20px !important;
}

.btnsty {
  width: 196px;
  height: 62px;
  border-radius: 10px 10px 10px 10px;
  background: white;
  color: #3E74EE !important;
  border: none;
  font-weight: bold;
  font-size: 28px !important;
}

::v-deep .van-badge__wrapper {
  font-size: 30px !important;
}

::v-deep .van-icon {
  font-size: 32px;
}

::v-deep .van-nav-bar__content {
  height: 70px;
  line-height: 70px;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.van-nav-bar__content {
  box-shadow: none !important;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

::v-deep .van-field__label {
  margin-top: 20px;
  font-size: 40px;
  color: #000;
  width: 30%;
}

::v-deep .van-button {
  margin-top: 20px;
  font-size: 20px;
  color: #fff;
  width: 30%;
  height: 80px;
  border-radius: 10px;
  background-color: #3E74EE;
  border-color: #3E74EE;
}



.pool-list {
  width: 90%;
  margin-top: 30px;
  padding-bottom: 20px;
  border-radius: 20px;
  margin-left: 3%;
  color: #fff;
  background: rgb(30, 35, 41);
  opacity: 1;
  padding: 15px;
  border: #3E74EE;
  height: 140px;
  display: flex;
  .text-list {
    color: rgb(234, 236, 239);
    background-color: rgb(30, 35, 41);
    font-weight: bold;
    font-size: 25px;
    display: flex;
    height: 100px;
    img{
      width: 90px;
      height: 90px;
      margin-left: 23%;
    }

  }
  .shbtn{
    position: absolute;
    height: 100px;
    width: 100px;
    font-size: 30px;
    border-radius: 10px;
    margin-top: -10%;
  }
}
</style>
